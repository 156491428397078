// List Icons
// -------------------------

.#{$fa-css-prefix}-ul {
  list-style-type: none;
  margin-left: $fa-li-width * 5/4;
  padding-left: 0;

  > li { position: relative; }
}

.#{$fa-css-prefix}-li {
  left: -$fa-li-width;
  position: absolute;
  text-align: center;
  width: $fa-li-width;
  line-height: inherit;
}

;@import "sass-embedded-legacy-load-done:108";