.acc {
    max-width: 1100px;
    margin: auto;
    margin-top: 2rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        text-align: left;
    } 

    .transition,
    ul li i:before,
    ul li i:after,
    p {
        transition: all 0.25s ease-in-out;
    }

    .no-select,
    h2 {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    p {
        font-size: 17px;
        line-height: 26px;
        letter-spacing: 1px;
        position: relative;
        overflow: hidden;
        max-height: 800px;
        opacity: 1;
        transform: translate(0, 0);
        margin-top: 14px;
        z-index: 2;
    }
    
    ul {
        list-style: none;
        perspective: 900;
        padding: 0;
        margin: 0;
    }

    ul li {
        position: relative;
        padding: 0;
        margin: 0;
        padding-bottom: 4px;
        padding-top: 0.5rem;
        border-top: 1px dotted #dce7eb;
    }
    
    ul li:nth-of-type(1) {
        animation-delay: 0.5s;
    }
    
    ul li:nth-of-type(2) {
        animation-delay: 0.75s;
    }
    
    ul li:nth-of-type(3) {
        animation-delay: 1s;
    }

    ul li:last-of-type {
        padding-bottom: 0;
    }
    
    ul li i {
        position: absolute;
        transform: translate(-6px, 0);
        margin-top: 16px;
        right: 0;
    }
    
    ul li i:before,
    ul li i:after {
        content: "";
        position: absolute;
        background-color: #777777;
        width: 3px;
        height: 9px;
    }

    ul li i:before {
        transform: translate(-2px, 0) rotate(45deg);
    }
    
    ul li i:after {
        transform: translate(2px, 0) rotate(-45deg);
    }
    
    ul li input[type="checkbox"] {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }
    
    ul li input[type="checkbox"]:checked ~ p {
        margin-top: 0;
        max-height: 0;
        opacity: 0;
        transform: translate(0, 50%);
    }

    ul li input[type="checkbox"]:checked ~ i:before {
        transform: translate(2px, 0) rotate(45deg);
    }

    ul li input[type="checkbox"]:checked ~ i:after {
        transform: translate(-2px, 0) rotate(-45deg);
    }
}

/*
      .acc .flipIn,
      .acc ul li,
      .acc h1 {
        animation: flipdown 0.5s ease both;
      }*/

@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}

;@import "sass-embedded-legacy-load-done:2682";