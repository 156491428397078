/**
 Salesforce Specific CSS Overrides
 https://drive.google.com/drive/folders/1ty-qDvDPVuFLMyf4OPyPHLm-6QHzDJ5s
 */
#onetrust-consent-sdk div#optanon-popup-bg{background:#fff;opacity:.75}
#onetrust-consent-sdk div#onetrust-pc-sdk{margin-top:0 !important;width:1008px;max-width:1008px;height:549px;position:fixed;top:50px !important;bottom:0;left:0;right:0;margin:auto;-webkit-box-shadow:0 5px 7px rgba(0,0,0,0.3);-moz-box-shadow:0 5px 7px rgba(0,0,0,0.3);box-shadow:0 5px 7px rgba(0,0,0,0.3);background-color:#f4f4f4}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk{width:auto;height:auto;max-height:95vh;margin-left:10px;margin-right:10px;top:10px !important;overflow:scroll;bottom:unset}
}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk{overflow-y:scroll;overflow-x:hidden}
}
#onetrust-consent-sdk div#onetrust-pc-sdk #optanon-popup-bg{background:#fff;opacity:.75}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header{height:137px;border-bottom:0;padding:0;display:inline-block;width:100%;vertical-align:middle}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header{background-color:#f3f3f3;height:83px}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-pc-logo-container{margin-left:0}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-pc-logo{height:60px;width:180px;background-position:center !important;background-size:contain;background-repeat:no-repeat;margin-top:35px;display:block}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-pc-logo{margin-top:15px !important}
}
@media(max-width:425px),(max-height:425px) and (max-width:896px) and (orientation:landscape){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-pc-logo{width:150px !important;height:40px !important}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header #ot-pc-title{font-family:"SalesforceSansLight",Helvetica,Arial,sans-serif;font-size:42px !important;font-weight:100;color:#222;top:27px;left:130px;position:absolute}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header #ot-pc-title{top:20px !important;font-size:21px !important;margin-left:10px !important;margin-top:10px !important;padding:0}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-title-cntr{position:static;display:block;width:100%;padding-left:10px}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-title-cntr #ot-pc-title{float:left;margin-left:30px;margin-top:15px;top:30px}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-title-cntr #ot-pc-title::before,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-header .ot-title-cntr #ot-pc-title::after{content:none;background-color:#f4f4f4;height:0;width:0;position:static}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-enbl-chr.ot-hosts-ui#ot-pc-lst .ot-lst-cntr{height:96%;background-color:#fff}
#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content{height:calc(-96%);margin-left:0;margin:0 10px 0 0}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content{margin:0;height:auto !important;min-height:380px}
}
@media(max-width:1024px){#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content{height:auto !important;min-height:300px}
}
#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-content .ot-grps-cntr{height:100%;position:relative;max-width:100%;margin:0 auto;padding:0}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list{overflow:hidden;padding-left:2%}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list{padding:0}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list ul.ot-cat-grp{float:left;display:initial;width:100%}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list ul.ot-cat-grp li{margin:0;min-height:45px;width:100%}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list ul.ot-cat-grp li .category-menu-switch-handler{padding:12px;border-left:4px solid transparent;border-bottom:0}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list ul.ot-cat-grp li .category-menu-switch-handler h3{font-family:"SalesforceSansRegular",Helvetica,Arial,sans-serif;font-size:15px !important;line-height:21px !important;color:#0b5cab;font-weight:normal}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list ul.ot-cat-grp li .category-menu-switch-handler.ot-active-menu{border-left:4px solid #00a1e0;background-color:#fff}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-sdk-columns.ot-tab-list ul.ot-cat-grp li .category-menu-switch-handler.ot-active-menu h3{color:#000;font-family:"SalesforceSansBold",Helvetica,Arial,sans-serif}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc{height:auto;min-height:340px;background-color:#fff;padding:1%;margin-left:0;width:69%}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc #ot-pvcy-hdr,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-cat-header{font-family:"SalesforceSansBold",Helvetica,Arial,sans-serif;font-size:22px !important;line-height:2.36 !important;color:#032e61}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-grp-desc{font-family:"SalesforceSansRegular",Helvetica,Arial,sans-serif;font-size:15px;line-height:1.6;color:#222;word-break:normal !important}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-desc-cntr{margin-top:0}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-desc-cntr .ot-grp-hdr1{padding-top:0}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc,#onetrust-consent-sdk div#onetrust-pc-sdk #ot-tab-desc,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-desc-cntr{padding:0;margin:0;min-height:auto;background-color:#fff}
}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc #ot-pvcy-hdr,#onetrust-consent-sdk div#onetrust-pc-sdk #ot-tab-desc #ot-pvcy-hdr,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-desc-cntr #ot-pvcy-hdr,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-cat-header,#onetrust-consent-sdk div#onetrust-pc-sdk #ot-tab-desc .ot-cat-header,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-desc-cntr .ot-cat-header,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .category-menu-switch-handler h3,#onetrust-consent-sdk div#onetrust-pc-sdk #ot-tab-desc .category-menu-switch-handler h3,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-desc-cntr .category-menu-switch-handler h3{padding:10px 0 5px 10px;font-family:"SalesforceSansBold",Helvetica,Arial,sans-serif;font-size:22px !important;line-height:2.36 !important;color:#032e61}
}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-grp-desc,#onetrust-consent-sdk div#onetrust-pc-sdk #ot-tab-desc .ot-grp-desc,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-desc-cntr .ot-grp-desc{padding:5px 20px 20px 20px;font-family:"SalesforceSansRegular",Helvetica,Arial,sans-serif;font-size:15px;line-height:1.6;color:#222;word-break:normal !important}
}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-hlst-cntr,#onetrust-consent-sdk div#onetrust-pc-sdk #ot-tab-desc .ot-hlst-cntr,#onetrust-consent-sdk div#onetrust-pc-sdk .ot-desc-cntr .ot-hlst-cntr{padding:0 0 30px 20px;margin:0 0 20px 0}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-always-active{font-size:16px;line-height:1.3;color:#1a73e8;font-weight:normal}
#onetrust-consent-sdk div#onetrust-pc-sdk .category-host-list-handler{font-size:12.8px;line-height:14.08px;color:#1a73e8}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl{padding-right:0}
@media(max-width:1023px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl{margin:20px 20px 0 0}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl .ot-switch{position:relative;display:inline-block;width:37px;height:24px;margin-bottom:0}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl .ot-switch .ot-switch-nob{background-color:#c1b9b4}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl .ot-switch .ot-switch-nob::before{position:absolute;content:"";height:20px;width:20px;-webkit-transition:.4s;border-radius:100%;top:2px;transition:.4s;left:2px;background-color:#fff}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob::before{-webkit-transform:translateX(13px);-ms-transform:translateX(13px);transform:translateX(13px)}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob{background-color:#0b5cab}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-lst-subhdr #ot-search-cntr{background-color:transparent}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-lst-subhdr #vendor-search-handler{font-size:12.8px}
@media(min-width:640px){#onetrust-consent-sdk div#onetrust-pc-sdk #ot-lst-cnt{width:calc(100% - 183px);padding-left:80px;padding-right:100px;padding-top:20px}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-opts{padding-right:15px}
#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst{height:calc(100% - 194px) !important}
#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst #ot-lst-title span{color:#0b5cab;font-size:14.4px;text-transform:uppercase}
#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-host-name{font-size:12.8px !important;line-height:1.2 !important}
#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-host-desc{font-size:11.04px !important;line-height:1.4 !important;color:#696969 !important}
#onetrust-consent-sdk div#onetrust-pc-sdk #ot-pc-lst .ot-host-expand{font-size:11.2px;color:#696969 !important}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-host-item .ot-host-opt li>div div{font-size:12.8px;color:#6e8290}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-chkbox input:checked ~ label::before{background-color:#4285f4}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-chkbox label::before{height:18px;width:18px;border:1px solid #1a73e8;left:0}
#onetrust-consent-sdk div#onetrust-pc-sdk button#clear-filters-handler{font-size:14.4px}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-fltr-opt span{font-size:12.8px}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-fltr-btns button{font-size:12.8px;border-radius:2px;background-color:#215ca0;border-color:#215ca0}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer{padding:0 30px 20px;border-top:0;position:static;height:34px;width:auto;float:none}
@media(max-width:640px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer{height:auto}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-pc-footer-logo{display:none}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container{float:right;text-align:center}
@media(max-width:640px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container{float:none}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container .ot-btn-subcntr{max-width:100%}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button.save-preference-btn-handler.onetrust-close-btn-handler{visibility:hidden;padding:0;border:0;margin:0;height:47px;min-width:155px;border-radius:4px;margin-left:15px;margin-right:15px;background-color:#0b5cab !important;color:#fff !important;border-color:#0b5cab !important;padding:14px 24px;letter-spacing:.01em;display:inline-block;text-transform:uppercase;font-size:14px}
@media(max-width:640px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button.save-preference-btn-handler.onetrust-close-btn-handler{margin:20px 0 10px;display:none}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button.save-preference-btn-handler.onetrust-close-btn-handler.visible{visibility:visible !important}
@media(max-width:640px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button.save-preference-btn-handler.onetrust-close-btn-handler.visible{display:inline-block}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler{display:inline-block !important;font-size:14px;font-weight:normal;height:48px;padding:14px 24px;line-height:1.1;text-align:center;min-width:155px;border-color:#0b5cab !important;border-radius:4px;text-transform:uppercase;margin:0 15px;background-color:#0b5cab !important;color:#fff !important}
@media(max-width:640px){#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler{margin:5px 0 20px}
}
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler.optanon-ghost-button{background-color:transparent !important;color:#0b5cab !important;font-size:12.8px;padding:0 30px}
div#onetrust-banner-sdk{max-width:none !important;min-height:194px;top:160px !important;left:0;right:0;margin:0 auto;-webkit-box-shadow:0 3px 7px rgba(0,0,0,0.3);-moz-box-shadow:0 3px 7px rgba(0,0,0,0.3);box-shadow:0 3px 7px rgba(0,0,0,0.3)}
@media(min-width:897px){div#onetrust-banner-sdk{width:900px !important}
}
div#onetrust-banner-sdk .optanon-alert-box-bottom-top{height:0}
div#onetrust-banner-sdk #onetrust-policy{margin-top:0}
div#onetrust-banner-sdk .ot-sdk-container p{color:#000 !important;font-size:11pt !important;line-height:16pt !important;margin:0 30px !important;font-family:"SalesforceSansRegular",Helvetica,Arial,sans-serif;padding-bottom:0;padding-top:30px}
@media(max-width:768px){div#onetrust-banner-sdk .ot-sdk-container p{color:#181818 !important;font-size:15px !important;line-height:24px !important}
}
div#onetrust-banner-sdk .ot-sdk-container p a{text-decoration:none !important;font-family:"SalesforceSansBold",Helvetica,Arial,sans-serif;color:#0b5cab;cursor:pointer}
div#onetrust-banner-sdk .ot-sdk-container #onetrust-button-group-parent #onetrust-button-group,div#onetrust-banner-sdk .ot-sdk-container #onetrust-button-group-parent .banner-actions-container{float:left}
div#onetrust-banner-sdk .ot-sdk-container #onetrust-button-group-parent #onetrust-accept-btn-handler{background-color:#0b5cab;height:47px;border-radius:4px;padding:14px 24px;font-size:14px;border:0;width:auto;line-height:100%;text-transform:uppercase}
div#onetrust-banner-sdk .has-reject-all-button #onetrust-pc-btn-handler{display:inline-block !important;font-size:14px;font-weight:normal;min-height:48px;padding:14px 24px;line-height:1.1;text-align:center;min-width:155px;border-radius:4px;text-transform:uppercase;margin:0 15px;float:none;max-width:90%}
@media(min-width:897px){div#onetrust-banner-sdk .has-reject-all-button #onetrust-pc-btn-handler{float:right}
}
div#onetrust-banner-sdk .has-reject-all-button .banner-actions-container{max-width:100%}
@media(max-width:896px){div#onetrust-banner-sdk .has-reject-all-button .banner-actions-container{float:none !important;text-align:center}
}
div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-reject-all-handler{border:0 !important;height:47px !important;min-width:155px !important;border-radius:4px !important;margin:0 15px 10px 0 !important;background-color:#0b5cab !important;color:#fff !important;border-color:#0b5cab !important;padding:14px 24px !important;letter-spacing:.01em !important;display:inline-block !important;text-transform:uppercase !important;font-size:14px !important}
@media(max-width:640px){div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-reject-all-handler,div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-pc-btn-handler,div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-accept-btn-handler{width:100%;margin-left:0 !important;margin-right:0 !important;max-width:100%}
    div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-reject-all-handler{margin-left:0 !important}
}

/** Prettier blues for NTO (via jmartins) */
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc {
}
.ot-sdk-container{
    .ot-sdk-four{
        background-color: #F4F4F4 !important;
    }
    a, .ot-link-btn, .ot-always-active{
        color: $blue !important;
    }
}
#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob:before{
    background-color:$blue !important;
}
#onetrust-consent-sdk #onetrust-pc-sdk button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn), #onetrust-consent-sdk #onetrust-pc-sdk .ot-leg-btn-container .ot-active-leg-btn{
    background-color:$blue !important;
    border-color: $blue !important;
    color: white !important;
}

;@import "sass-embedded-legacy-load-done:2662";